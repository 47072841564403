import React, { useEffect, useState, memo } from 'react';
import loadable from '@loadable/component';
import { Link } from 'gatsby';
import {
  formatPrice,
  getJsonMetaFeildValue,
  loaded,
  overwriteHidePrice,
} from '../../utils/helpers';
import LazyLoad from 'react-lazyload';
import { OkendoStarRating } from '../common/OkendoStarSnippet';
import { useStore } from '../../hooks';
import ProductImage from './ProductImage';
import ProductImageMobile from './ProductImageMobile';
import ProductContentTabs from './ProductContentTabs';
import ListVariants from './ListVariants';
import SelectVariants from './SelectVariants';
import ProductPrice from '../common/ProductPrice';
import PaymentOptions from './PaymentOptions';
import BreadcrumbBrand from './BreadcrumnBrand';
import ClickCollect from './ClickCollect';
import AddToCart from './AddToCart';
import Scrollmagic from './Scrollmagic';
import EnterPassword from './EnterPassword';
import { hasPwBrand } from '../../utils/product-password';

const RelatedProducts = loadable(() => import('./RelatedProducts'));
const RelatedProductsSeller = loadable(() => import('./RelatedProductsSeller'));
const SliderBlogPost = loadable(() => import('../home_page/SliderBlogPost'));
const OkendoReviewsWidget = loadable(() => import('./OkendoReviewsWidget'));

function SingleProduct({
  product,
  brand,
  images,
  swatch_images,
  relatedProducts,
  relatedProductsSeller,
}) {
  // if (!loaded) return;
  const isMobile = loaded && window.innerWidth < 992;
  const { metafields, brands } = useStore();
  const metafield = metafields[product?.id] || [];
  const [state, setState] = useState({
    product,
    variant: product?.variants?.[0],
    changeVariant: false,
    price: null,
    compareAtPrice: null,
    purchase_method: metafield?.filter((v) => v?.key === 'purchase_method')?.[0]?.value || null,
    hide_price: true,
  });

  const [data, setData] = useState({
    isFreeGift: false,
    valuePrice: false,
    summaryData: {},
    reuiredPassword: false,
  });

  const password = brands?.[brand?.data?.name];

  const requiredPassword =
    state.purchase_method === 'Password Protected' &&
    password &&
    !hasPwBrand(brand?.data?.name, password);

  if (requiredPassword)
    return (
      <EnterPassword setState={setState} password={password} brand={brand} product={product} />
    );

  useEffect(() => {
    const summaryData = getJsonMetaFeildValue(
      metafield?.filter((v) => v?.key === 'summaryData')?.[0]?.value
    );

    // const rating = getJsonMetaFeildValue(
    //   metafield.filter((v) => v?.key === 'rating')?.[0]?.value
    // );

    setData((_data) => ({
      ..._data,
      summaryData,
    }));
  }, []);

  useEffect(() => {
    const isFreeGift =
      state.price === '0.00' || product?.tags?.findIndex((v) => v === 'FreeGift') !== -1;

    // let hide_price =
    //   metafield.filter((v) => v.key === 'hide_price')?.[0]?.value == 'true' ? true : false;
    // hide_price = overwriteHidePrice(product.vendor, hide_price, state.purchase_method);

    let valuePrice = metafield.filter((v) => v?.key === 'value_price_2')?.[0]?.value;

    setData((_data) => ({
      ..._data,
      isFreeGift,
      // hide_price,
      valuePrice,
    }));
  }, [metafields]);

  relatedProducts = relatedProducts?.filter((v) => {
    return v?.tags?.findIndex((v) => v === 'FreeGift') === -1;
  });

  const { isFreeGift, valuePrice, summaryData } = data;
  const { hide_price, purchase_method } = state;
  const date = new Date();
  date.setFullYear(date.getFullYear() + 5);

  return (
    <div className="main__product-view">
      <div className="container-xl">
        <div className="row">
          <div className="col-12">
            <BreadcrumbBrand
              brands={brand?.data?.parent_brand}
              brand_current={{ slug: brand?.url, label: brand?.data?.name }}
              current={{ slug: product.handle, label: product.title }}
            />
          </div>
        </div>

        <div itemScope itemProp="brand" itemType="http://schema.org/Brand">
          <meta itemProp="name" content={brand?.data?.name || ''} />
        </div>

        <div className="row" style={{ overflow: 'hidden' }}>
          <div className="col-xl-7 col-lg-6">
            <div className="product-name d-lg-none pt-0">
              <h1>{product.title}</h1>
            </div>
            <meta itemProp="image" content={product?.featuredImage?.originalSrc} />
            <div className="d-lg-none d-block">
              <ProductImageMobile isFreeGift={isFreeGift} state={state} _images={images} />
            </div>

            <div>
              {!isMobile && (
                <Scrollmagic>
                  <div id="product-review" className="d-none d-lg-block">
                    <ProductImage
                      isFreeGift={isFreeGift}
                      state={state}
                      brand={brand}
                      _images={images}
                    />
                  </div>
                </Scrollmagic>
              )}
            </div>
          </div>

          <div className="col-xl-5 col-lg-6" id="product-detail">
            <form className="main__product-view__form-add-to-cart">
              <div className="product-name d-none d-lg-block">
                {!isMobile && <h1>{product.title}</h1>}
              </div>

              {isFreeGift ? (
                <div className="free-price">FREE GIFT</div>
              ) : (
                <>
                  {!hide_price && (
                    <div className="product-price">
                      {state.price && (
                        <ProductPrice price={state.price} compareAtPrice={state.compareAtPrice} />
                      )}
                      {valuePrice && (
                        <span className="value-price">
                          VALUE {formatPrice(Number(valuePrice || 0))}
                        </span>
                      )}
                    </div>
                  )}

                  {!hide_price && state.price && <PaymentOptions variant={state.variant} />}

                  <div className="product-rating">
                    {/* <div itemProp="aggregateRating" itemType="https://schema.org/AggregateRating">
                      <meta itemProp="reviewCount" content="89" />
                      <meta itemProp="ratingValue" content="4.4" />
                    </div> */}

                    {summaryData?.reviewCount > 0 && (
                      <>
                        <div
                          itemProp="aggregateRating"
                          itemScope
                          itemType="https://schema.org/AggregateRating"
                        >
                          <meta itemProp="reviewCount" content={summaryData?.reviewCount || 0} />
                          <meta
                            itemProp="ratingValue"
                            content={summaryData?.reviewAverageValue || 0}
                          />
                        </div>
                        {/* <div itemProp="review" itemScope itemType="https://schema.org/Review">
                          <span itemProp="author" itemscope itemtype="https://schema.org/Person">
                            <meta itemProp="name" content="Activeskin" />
                          </span>

                          <div
                            itemProp="reviewRating"
                            itemScope
                            itemType="https://schema.org/Rating"
                          >
                            <meta itemprop="worstRating" content={rating?.scale_min || 0} />
                            <meta itemProp="ratingValue" content={rating?.value || 0} />
                            <meta itemprop="bestRating" content={rating?.scale_max || 0} />
                          </div>
                        </div> */}
                      </>
                    )}
                    <OkendoStarRating productId={product.legacyResourceId} hide={true} />
                  </div>

                  {product?.variants && product?.variants[0]?.title !== 'Default Title' && (
                    <>
                      {/* list variants */}
                      <ListVariants
                        className="list-variants"
                        product={product}
                        setVariant={(variant) =>
                          setState((_state) => ({ ..._state, variant, changeVariant: true }))
                        }
                        images={swatch_images}
                      />
                      {/* ./ list variants */}

                      {/* select variants */}
                      <SelectVariants
                        className="select-variants"
                        product={product}
                        variant={state.variant}
                        setVariant={(variant) =>
                          setState((_state) => ({ ..._state, variant, changeVariant: true }))
                        }
                        images={swatch_images}
                      />
                      {/* ./ select variants */}
                    </>
                  )}

                  <ClickCollect product={product} metafield={metafield} />

                  <AddToCart
                    metafield={metafield}
                    hide_price={hide_price}
                    purchase_method={purchase_method}
                    product={product}
                    variant={state.variant}
                    setState={setState}
                  />
                  {/* <ReviewServices /> */}
                </>
              )}
            </form>

            {/* tabs description */}
            <ProductContentTabs isFreeGift={isFreeGift} product={product} variant={state.variant} />
          </div>
        </div>
        {/*end row*/}
      </div>

      {!isFreeGift && (
        <>
          {/* products related */}
          {relatedProducts?.length > 0 ? (
            <RelatedProducts products={relatedProducts} />
          ) : (
            <RelatedProductsSeller products={relatedProductsSeller} />
          )}
          {/* slider blog posts */}
          <SliderBlogPost isHomePage={false} />
          <div className="okendo-widget-container container-xl mb-3">
            {/* product?.legacyResourceId */}
            <LazyLoad throttle={500} height={100} scroll={true}>
              <OkendoReviewsWidget productId={product.legacyResourceId} />
            </LazyLoad>
          </div>
        </>
      )}
    </div>
  );
}

export default memo(SingleProduct);
